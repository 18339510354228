import { AMaterialLessonBlockTemplate } from "../../models";

const images = ["/images/figma_image_rect.png"];
const text = {
  title: {
    sample: "this is a text title",
    size: "61px",
    color: "var(--primary)",
  },
  subtitle: {
    sample: "this is a text subtitle",
    size: "14px",
    color: "var(--accent)",
  },
  desc: {
    sample: "this is a text description",
    size: "16px",
    color: "black",
  },
};

export const SectionOneContents: AMaterialLessonBlockTemplate[] = [
  // Section 1 Content 1
  {
    name: "Section 1 Content 1",
    template: {
      name: "Section 1 content 1",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "50%",
            height: "100%",
          },
        },
        // right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
                fontWeight: "bold",
              },
            },
          ],
          style: {
            width: "50%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "40px",
      },
    },
  },

  // Section 1 Content 2
  {
    name: "Section 1 Content 2",
    template: {
      name: "Section 1 content 2",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "50%",
            height: "100%",
          },
        },
        // right side:
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
                fontWeight: "bold",
              },
            },
          ],
          style: {
            width: "50%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        gap: "20px",
        flexDirection: "row-reverse",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "40px",
      },
    },
  },
];
